<template>
  <div class="main">
    <!-- section calendar -->
    <section class="section-full">
      <div class="box">
        <div class="row">
          <div class="col-60">
            <img src="./../assets/education1jpg.jpg" class="education-img" alt="academy" />
          </div>
          <div class="col-40">
            <h2>{{$t("education.title1")}}</h2>
            <p v-html="$t('education.text1')"></p>
            <p v-html="$t('education.text2')"></p>
            <router-link :to="{name : 'Calendar'}" class="btn"><i class="fa-solid fa-calendar btn-icon"></i>{{ $t("education.calendar")}}</router-link>
          </div>
        </div>
      </div>
    </section>
    <!-- section peer -->
    <section class="section-full">
      <div class="box">
        <div class="row reverse">
          <div class="col-60">
            <img src="./../assets/education2.jpg" class="education-img" alt="academy" />
          </div>
          <div class="col-40">
            <h2>{{$t("education.title2")}}</h2>
            <p v-html="$t('education.text3')"></p>
            <p v-html="$t('education.text4')"></p>
          </div>
        </div>
      </div>
    </section>
    <!-- section company -->
    <section class="section-full">
      <div class="box">
        <div class="row">
          <div class="col-60">
            <img src="./../assets/education3.jpg" class="education-img" alt="academy" />
          </div>
          <div class="col-40">
            <h2>{{$t("education.title3")}}</h2>
            <p v-html="$t('education.text5')"></p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: "Education",
}
</script>

<style scoped lang="scss">

.education-img {
  width: 80%;
  margin:0 auto;
  display: block;
}

.col-40 {
  padding-left:20px;
  border-left:2px solid $mainColor;
}

.reverse .col-40 {
  padding-left:0;
  padding-right:20px;
  border-left:0;
  border-right:2px solid $mainColor;
}

.reverse .education-img{
  margin-top:20px;
}

.reverse h2, .reverse p {
  text-align: right;
}

h2 {
  color:$mainColor;
  font-size:35px;
  font-weight:400;
}

p {
  font-size:20px;
}
@media screen and (max-width:768px) {
  .education-img, .reverse .education-img {
    width:450px;
    max-width: 90%;
    margin-bottom:40px;
    margin-top:80px;
  }

  p {
    font-size:15px;
  }
}
</style>